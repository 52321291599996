<template>
<!-- 상태 변경 팝업 -->
<transition name="modal" appear>
    <div class="modal modal-overlay" v-if="onRegisterModal" @click.self="handleWrapperClick">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content" style="width:1200px">
                <p class="bold dp-inblock h-30px font-23">예약 하기</p>
                <!-- <a class="pointer float-right layer-close ml-30" @click="$emit('close')" data-dismiss="modal" aria-label="Close">
                            <img src="@/assets/images/close.png" alt="close" class="close-btn">
                        </a> -->
                <div class="con">
                    <div class="con_table mt-20">
                        <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00 w-100per">
                            <tbody>
                                <tr>
                                    <th>고객명 <span class="red">*</span></th>
                                    <td class="left pl-20">
                                        <!-- <input type="radio" id="new" value="new" v-model="customerType" /><label for="new">신규고객</label> -->
                                        <input class="ml-20" type="radio" id="old" value="old" v-model="customerType" /><label for="old">기존고객</label><br>
                                        <div v-if="customerType === 'old'" id="app" v-on:keyup.down="selectValue('down')" v-on:keyup.up="selectValue('up')">
                                            <div class="search">
                                                <input type="text" v-model="name" class="ml-10" style="width:400px;" id="name" name="name" @input="watchEvent($event, 'client')">
                                                <ul class="c" tabindex="0" v-bind:class="{ show: isAutoClient }" v-on:mouseover="removeValue">
                                                    <li tabindex="-1" v-for="(el, index) in filterClientList" v-on:click="changeValue(el.name, 'client', el.idx)" v-on:keyup.enter="selectValue('enter', el.name)" :key="index">
                                                        <span>{{ el.name }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div v-if="customerType === 'new'">
                                            <input v-model="name" type="text" class="ml-10" style="width:400px;" value="">
                                        </div>
                                    </td>
                                    <th>성별</th>
                                    <td class="left pl-20">
                                        <select v-model="gender" class="w-20px" :disabled="customerType === 'old'">
                                            <option value="">성별</option>
                                            <option value="남자">남</option>
                                            <option value="여자">여</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>생년월일</th>
                                    <td class="left pl-30">
                                        <crm-datepicker v-if="customerType === 'new'" v-model="birth" :parentDate="birth" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                                        <input v-if="customerType === 'old'" type="text" v-model="birth" disabled />
                                    </td>
                                    <th>이메일</th>
                                    <td class="left pl-20">
                                        <input :disabled="customerType === 'old'" v-model="email" type="text" class="w-200px" value="">
                                    </td>
                                </tr>
                                <tr>
                                    <th>휴대폰 번호</th>
                                    <td class="left">
                                        <select :disabled="customerType === 'old'" class="ml-20" v-model="phone1" id="phone1" name="phone1">
                                            <option value="010">010</option>
                                            <option value="011">011</option>
                                            <option value="016">016</option>
                                            <option value="017">017</option>
                                            <option value="018">018</option>
                                            <option value="019">019</option>
                                        </select> -
                                        <input :disabled="customerType === 'old'" type="text" v-model="phone2" style="width:100px;" id="phone2" name="phone2" value=""> -
                                        <input :disabled="customerType === 'old'" type="text" v-model="phone3" style="width:100px;" id="phone3" name="phone3" value="">

                                    </td>
                                    <th>유입경로</th>
                                    <td class="left pl-20">
                                        <select :disabled="customerType === 'old'" v-model="idxCrmMetaRoute" class="w-120px">
                                            <option value="-1">유입경로DB</option>
                                            <option v-for="(item, index) of routeList" :key="index" :value="item.idx">{{item.type}}</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="bold w-100px">예약일자 </th>
                                    <td class="left pl-20">
                                        <crm-datepicker class="ml-10" @input="getStaffSchedule()" v-model="consStartDate" :parentDate="consStartDate" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                                        <!-- <button type="button" class="default-btn ml-20" @click="getStaffSchedule()">상담사 스케쥴보기</button> -->
                                    </td>
                                    <th style="width: 111px;">예약현황 <span class="red">*</span></th>
                                    <td class="left pl-20">
                                        <select v-model="reservationStatus" class="w-120px">
                                            <option value="">예약현황선택</option>
                                            <option value="예약">예약</option>
                                            <option value="당일취소">취소</option>
                                            <option value="NS">NS</option>
                                            <option value="입실">입실</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="bold w-100px">예약시간 <span class="red">*</span></th>
                                    <!-- <td class="left" colspan="3">
                                        <select class="ml-10" v-model="consTime1" id="consTime1" name="consTime1">
                                            <option v-for="(item, index) of consTimeList" :key="index" :value="item.value">{{item.value}}</option>
                                        </select>
                                    </td> -->
                                    <td class="left" colspan="3">
                                        <table class="default_table w-1000px mb-20 mt-20 ml-20">
                                            <tbody>
                                                <tr>
                                                    <th>09:00</th>
                                                    <th>09:30</th>
                                                    <th>10:00</th>
                                                    <th>10:30</th>
                                                    <th>11:00</th>
                                                    <th>11:30</th>
                                                    <th>12:00</th>
                                                    <th>12:30</th>
                                                    <th>13:00</th>
                                                    <th>13:30</th>
                                                    <th>14:00</th>
                                                    <th>14:30</th>
                                                    <th>15:00</th>
                                                </tr>
                                                <tr>
                                                    <td v-for="(item, idx) of consTimeList.slice(0,13)" :key="idx"><input type="checkbox" v-model="item.use" :disabled="item.can === false" /> </td>
                                                </tr>
                                                <tr>
                                                    <th>15:30</th>
                                                    <th>16:00</th>
                                                    <th>16:30</th>
                                                    <th>17:00</th>
                                                    <th>17:30</th>
                                                    <th>18:00</th>
                                                    <th>18:30</th>
                                                    <th>19:00</th>
                                                    <th>19:30</th>
                                                    <th>20:00</th>
                                                    <th>20:30</th>
                                                    <th>21:00</th>
                                                    <th>21:30</th>
                                                </tr>
                                                <tr>
                                                    <td v-for="(item, idx) of consTimeList.slice(13,26)" :key="idx"><input type="checkbox" v-model="item.use" :disabled="item.can === false" /> </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <!-- <input type="text" class="ml-30 w-1000px" value=""> -->
                                    </td>
                                </tr>
                                <tr>
                                    <th class="bold">상담사</th>
                                    <td class="left" colspan="3">
                                        <div id="app" v-on:keyup.down="selectValue('down')" v-on:keyup.up="selectValue('up')">
                                            <div class="search">
                                                <input type="text" v-model="staffName" class="ml-10" style="width:200px;" id="counselor" name="counselor" @input="watchEvent($event, 'counselor')">
                                                <ul class="r" tabindex="0" v-bind:class="{ show: isAutoCounselor }" v-on:mouseover="removeValue">
                                                    <li tabindex="-1" v-for="(el, index) in filterCounselorList" v-on:click="changeValue(el.name, 'counselor', el.idx, el.idx_crm_center, el.center_name)" v-on:keyup.enter="selectValue('enter', el.name, el.idx, el.idx_crm_center, el.center_name)" :key="index">
                                                        <span>{{ el.name }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>상담유형</th>
                                    <td class="left pl-20">
                                        <select v-model="idxCrmMetaType" class="w-200px">
                                            <option value="-1">상담유형선택</option>
                                            <option v-for="(item, index) of typeList" :key="index" :value="item.idx">{{item.type}}</option>
                                        </select>
                                    </td>
                                    <!-- <th>진행현황</th>
                                        <td class="left pl-20">
                                            <select v-model="clientStatus" class="w-120px">
                                                <option value="">진행현황</option>
                                                <option value="의뢰">의뢰</option>
                                                <option value="종료">종료</option>
                                            </select>
                                        </td> -->
                                </tr>
                                <tr>
                                    <th style="width: 111px;">메모</th>
                                    <td class="left" colspan="3">
                                        <textarea type="text" v-model="memo" class="ml-10 w-98per h-100px" id="introduce" name="introduce" value="" />
                                        </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="btns3 mgB80">
                            <!-- <a class="btn_sms mr-10 pointer" @click="$emit('close')">닫기</a> -->
                            <a class="btn_sms mr-10 pointer" @click="$emit('update:onRegisterModal', !onRegisterModal)">닫기</a>
                            <a class="btn_cancle mr-10 pointer" @click="register()">등록</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</transition>
</template>

<script>
import {
    EmptyCheck
} from '@/utils/validation.js'
export default {

    props: {
        onRegisterModal: {
            type: Boolean,
            require: true,
            default: false
        },

        consStartDate: {
            type: String,
            require: true,
        },

        idxCrmStaff: {
            type: Number,
            require: true
        },

        staffName: {
            type: String,
            require: false
        },

        time: {
            type: String,
            require: false
        }

        /* idx: {
            type: Number,
            require: false
        } */

    },

    data: () => ({

        consTimeList: [],
        name: '',
        consDate: '',
        consTime: '',
        customerType: 'old',
        gender: '남',
        name: '',
        gender: '',
        birth: '',
        email: '',
        phone1: '010', // 휴대폰 앞자리
        phone2: '', // 휴대폰 가운데자리
        phone3: '', // 휴대폰 마지막자리
        routeList: [],
        idxCrmMetaRoute: -1,
        isAutoCounselor: false, // 상담사 자동완성 on/off
        autoSearchCounselor: '', // 상담사 자동완성 검색
        counselorList: [], // 상담사 리스트 (자동완성)
        isAutoClient: false, // 유저 자동완성 on/off
        autoSearchClient: '', // 유저 자동완성 검색
        clientList: [] // 유저 리스트 (자동완성)
            ,
        workTimeList: [],
        typeList: [],
        idxCrmMetaType: -1,
        clientStatus: '',
        reservationStatus: '',
        eapYn: 'N',
        idxCrmPerson: -1,
        idxCrmClient: -1,
        memo: ''

    }),

    computed: {
        filterCounselorList() {
            const str = this.autoSearchCounselor
            const reg = /[^가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9|\s]/.test(str)
            if (reg === false && str !== '' && str !== ' ') {
                this.isAutoCounselor = true
                var filterNames = []
                filterNames = this.counselorList.filter((el) => {
                    return el.name.match(str);
                });
                if (filterNames.length > 0) {
                    return filterNames
                } else {
                    this.isAutoCounselor = false
                }
            } else {
                this.isAutoCounselor = false
            }
        },

        filterClientList() {
            const str = this.autoSearchClient
            const reg = /[^가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9|\s]/.test(str)
            if (reg === false && str !== '' && str !== ' ') {
                this.isAutoClient = true
                var filterNames = []
                filterNames = this.clientList.filter((el) => {
                    return el.name.match(str);
                });
                if (filterNames.length > 0) {
                    return filterNames
                } else {
                    this.isAutoClient = false
                }
            } else {
                this.isAutoClient = false
            }
        }
    },

    mounted() {
        this.consDate = this.formatDate(this.consStartDate)
        this.birth = this.formatDate(new Date)
        this.getCounselorList()
        this.getMetaList()
        this.setConsTime()
        this.getPersonList()
    },

    methods: {

        radio() {
            this.customerType = 'old'
            this.name = ''
            this.gender = ''
            this.phone1 = '010'
            this.phone2 = ''
            this.phone3 = ''
            this.birth = this.formatDate(new Date)
            this.idxCrmMetaRoute = -1 
            this.idxCrmMetaType = -1
            this.email = '' 
        },

        handleWrapperClick() {
            this.$emit('update:onRegisterModal', false)
            radio()
        },

        register() {
            //alert('modal this.consStartDate : ' + this.formatDate(this.consStartDate))
            //this.$router.push(`/origin/consult_schedule?consStartDate=${this.consStartDate}`)
            //this.$router.go(this.$router.currentRoute, this.formatDate(this.consStartDate))
            if (this.customerType === 'new') {
                this.postPersonInfo()
            } else {
                this.postClientInfo()
            }

            //this.$router.go(this.$router.currentRoute)
            //this.handleWrapperClick()
        },

        postMemo() {

            var vo = {
                memo: this.memo,
                idxCrmPerson: this.idxCrmPerson,
            }

            this.axios.post('/api/v1/client/memo', vo)
                .then(res => {
                    if (res.data.err === 0) {
                        //alert('메모 등록이 완료되었습니다.')
                        //this.$router.push('/origin/customer_manage')
                        //this.$router.go(this.$router.currentRoute)
                    } else {
                        //alert(res.data.result)
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })

        },

        postReservationInfo() {

            // 근무요일 테이블 파라미터형태로 변경
            let consTimeList = []
            let copyConsTimeList = JSON.parse(JSON.stringify(this.consTimeList))
            for (var item of copyConsTimeList) {
                if (item.use) {
                    delete item['use']
                    consTimeList.push(item)
                }
            }

            console.log('consTimeList', consTimeList)
            this.consTime = ''
            consTimeList.reverse();
            for (var time of consTimeList) {
                this.consTime = time.time + "/" + this.consTime
            }

            var params = {
                consTime: this.consTime,
                consDate: this.formatDate(this.consStartDate),
                //memo: this.memo,
                name: this.name,
                idxCrmStaff: this.idxCrmStaff,
                idxCrmClient: this.idxCrmClient,
                reservationStatus: this.reservationStatus
            }
            if (this.idxCrmClient === -1) {
                alert('과정을 다시 시도해주세요.');
            }else{
            this.axios.post('/api/v1/cons', params)
                .then(res => {
                    if (res.data.err === 0) {
                        alert('등록되었습니다.')
                        this.$router.go(this.$router.currentRoute)
                    } else {
                        alert(res.data.result)
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.path === '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        }
    },

        postClientInfo() {

            let flag = false

            // 고객 자동완성 체크 부분
            if (!EmptyCheck(this.name, '고객을')) {
                return false
            } else {
                for (var client of this.clientList) {
                    if (client.name === this.name && client.idx === Number(this.idxCrmClient)) {
                        flag = true
                        break;
                    }
                }
            }

            if (this.customerType !== 'new') {
                if (!flag) {
                    alert('고객명을 다시 확인해주세요')
                    return false
                }

                if (this.idxCrmClient === -1) {
                    alert('존재하지 않는 고객입니다.')
                    return false
                }
            }

            if (!EmptyCheck(this.reservationStatus, '예약현황을')) {
                return false
            }

            // 근무요일 테이블 파라미터형태로 변경
            let consTimeList = []
            let copyConsTimeList = JSON.parse(JSON.stringify(this.consTimeList))
            for (var item of copyConsTimeList) {
                if (item.use) {
                    delete item['use']
                    consTimeList.push(item)
                }
            }

            console.log('consTimeList', consTimeList)
            this.consTime = ''
            consTimeList.reverse();
            for (var time of consTimeList) {
                this.consTime = time.time + "/" + this.consTime
            }

            if (!EmptyCheck(this.consTime, '상담시간을')) {
                return false
            }

            /* if (!EmptyCheck(this.clientStatus, '진행상황을')) {
                return false
            } */

            // 핸드폰 번호를 전부 입력한 경우에만 핸드폰 포맷으로 파싱
            if (this.phone2 !== '' && this.phone3 !== '') {
                var phone = this.phone1 + '-' + this.phone2 + '-' + this.phone3
            } else {
                var phone = ''
            }

            if (phone === '') {
                alert('핸드폰번호를 입력해주세요.')
                return false
            }

            var vo = {
                name: this.name,
                gender: this.gender,
                birth: this.formatDate(this.birth),
                email: this.email,
                idxCrmCompany: this.idxCrmCompany,
                idxCrmMetaRoute: this.idxCrmMetaRoute,
                idxCrmCenter: this.idxCrmCenter,
                idxCrmStaff: this.idxCrmStaff,
                idxCrmMetaProduct: this.idxCrmMetaProduct,
                etcPrice: this.etcPrice,
                idxCrmMetaType: this.idxCrmMetaType,
                //idxCrmMetaSubject: this.idxCrmMetaSubject,
                consCount: this.consCount,
                clientStatus: this.clientStatus,
                eapYn: this.eapYn,
                // memo: this.memo,
                idxCrmPerson: this.idxCrmPerson,
                phone: phone

            }
            
            this.axios.post('/api/v1/client', vo)
                .then(res => {
                    if (res.data.err === 0) {
                        console.log('차트 idx', res.data.idxCrmClient)
                        this.idxCrmClient = res.data.idxCrmClient
                        if(this.memo !== '') {
                            this.postMemo()
                        }
                        this.postReservationInfo()
                        //this.$router.push('/origin/customer_manage')
                    } else {
                        alert(res.data.result)
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
                
        },

        // 고객 전체목록 호출
        getPersonList() {

            this.axios.get('/api/v1/client/person/list', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.clientList = res.data.personList
                    } else {
                        // alert(res.data.result)
                        this.clientList = []
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.path === '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        getPersonData(idx) {
            var params = {
                idx: idx
            }
            this.axios.get(`/api/v1/client/person/${idx}`, {})
                .then(res => {
                    if (res.data.err === 0) {

                        // 휴대폰 번호 매핑
                        var phone = res.data.personVO.phone.split('-')
                        if (phone.length === 3) {
                            this.phone1 = phone[0]
                            this.phone2 = phone[1]
                            this.phone3 = phone[2]
                        }
                        this.idxCrmPerson = res.data.personVO.idx
                        this.gender = res.data.personVO.gender
                        this.birth = res.data.personVO.birth
                        this.email = res.data.personVO.email
                        this.idxCrmMetaRoute = res.data.personVO.idxCrmMetaRoute
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        getStaffSchedule() {

            this.time = ''
            let flag = false

            // 상담사 자동완성 체크 부분
            for (var staff of this.counselorList) {
                if (staff.name === this.staffName && staff.idx === Number(this.idxCrmStaff)) {
                    flag = true
                    break;
                }
            }

            if (!flag) {
                alert('상담사를 다시 확인해주세요')
                return false
            }

            if (this.idxCrmStaff === -1) {
                alert('존재하지 않는 상담사입니다.')
                return false
            }

            var params = {
                idxCrmStaff: this.idxCrmStaff,
                consDate: this.formatDate(this.consStartDate)
            }

            this.axios.get(`/api/v1/user/staff/schedule`, {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {

                        this.isValidCheck = true
                        this.schedule = res.data.schedule
                        this.setConsTime()
                        if (res.data.schedule.length > 0) {
                            for (var i = 0; i < res.data.schedule.length; i++) {
                                // 근무요일 테이블 매핑 (자기 자신거)
                                if (res.data.schedule[i].idx !== Number(this.idx)) {
                                    this.workTimeList = res.data.schedule[i].consTime.split('/')
                                    for (var consTime of this.consTimeList) {
                                        // 상담사 일하는 시간 외에는 선택하지 못하도록 추가 20210118
                                        if (res.data.workTime.work_time.indexOf(consTime.time) === -1) {
                                            consTime.can = false
                                        }
                                        for (var work of this.workTimeList) {
                                            console.log('work ', work, ' time', consTime.time)
                                            if (work === consTime.time) {
                                                consTime.can = false // false 사용X true 사용O
                                                consTime.use = false
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            if (res.data.workTime !== null && res.data.workTime !== undefined && res.data.workTime !== 'null') {
                                for (var consTime of this.consTimeList) {
                                    if (res.data.workTime.work_time.indexOf(consTime.time) === -1) {
                                        consTime.can = false
                                    }
                                    if (this.time === consTime.time) {
                                        consTime.can = true
                                        consTime.use = true
                                    }
                                }
                            } else {
                                for (var consTime of this.consTimeList) {
                                    consTime.can = false
                                }
                            }
                        }
                    }
                }).catch(err => {
                    console.log(err)
                    if (err.response.data.path === '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })

        },

        getStaffSchedule2(idx, name, date) {

            let staffName = name
            let idxCrmStaff = idx
            let consDate = date
            let flag = false

            // 상담사 자동완성 체크 부분
            for (var staff of this.counselorList) {
                if (staff.name === staffName && staff.idx === Number(idxCrmStaff)) {
                    flag = true
                    break;
                }
            }

            if (!flag) {
                alert('상담사를 다시 확인해주세요')
                return false
            }

            if (idxCrmStaff === -1) {
                alert('존재하지 않는 상담사입니다.')
                return false
            }

            var params = {
                idxCrmStaff: idxCrmStaff,
                consDate: this.formatDate(this.consStartDate)
            }

            this.axios.get(`/api/v1/user/staff/schedule`, {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {

                        this.isValidCheck = true
                        this.schedule = res.data.schedule
                        this.setConsTime()
                        if (res.data.schedule.length > 0) {
                            for (var i = 0; i < res.data.schedule.length; i++) {
                                // 근무요일 테이블 매핑 (자기 자신거)
                                if (res.data.schedule[i].idx !== Number(this.idx)) {
                                    this.workTimeList = res.data.schedule[i].consTime.split('/')
                                    for (var consTime of this.consTimeList) {
                                        // 상담사 일하는 시간 외에는 선택하지 못하도록 추가 20210118
                                        if (res.data.workTime.work_time.indexOf(consTime.time) === -1) {
                                            consTime.can = false
                                        }
                                        if (this.time === consTime.time) {
                                            consTime.can = true
                                            consTime.use = true
                                        } else {
                                            for (var work of this.workTimeList) {
                                                //console.log('work ', work, ' time', consTime.time)
                                                if (work === consTime.time) {
                                                    consTime.can = false
                                                    consTime.use = false
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            if (res.data.workTime !== null && res.data.workTime !== undefined && res.data.workTime !== 'null') {
                                for (var consTime of this.consTimeList) {
                                    if (res.data.workTime.work_time.indexOf(consTime.time) === -1) {
                                        consTime.can = false
                                    }
                                    if (this.time === consTime.time) {
                                        consTime.can = true
                                        consTime.use = true
                                    }
                                }
                            } else {
                                for (var consTime of this.consTimeList) {
                                    consTime.can = false
                                }
                            }
                        }
                    }
                }).catch(err => {
                    console.log(err)
                    if (err.response.data.path === '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })

        },

        // 센터 자동완성 입력 감지
        watchEvent(e, type) {
            if (type === 'counselor') {
                this.autoSearchCounselor = e.target.value
            } else {
                this.autoSearchClient = e.target.value
            }
        },

        // 자동완성 끄기
        removeValue() {
            if (document.querySelector('.r').classList.contains('key')) {
                document.querySelector('.r').classList.remove('key');
                document.querySelector('.r li.sel').classList.remove('sel');
            }
            if (document.querySelector('.c').classList.contains('key')) {
                document.querySelector('.c').classList.remove('key');
                document.querySelector('.c li.sel').classList.remove('sel');
            }
        },

        // 자동완성 선택한 경우 센터명 변경
        changeValue(str, type, idx, centerIdx, centerName) {
            if (type === 'counselor') {
                console.log('idx', idx)
                console.log('centerIdx', centerIdx)
                console.log('centerName', centerName)
                this.isAutoCounselor = false
                this.staffName = str
                this.idxCrmStaff = idx

                //this.getUserList()
                this.getStaffSchedule()
            } else {
                console.log('idx', idx)
                this.isAutoClient = false
                this.name = str
                this.idxCrmClient = idx
                this.getPersonData(idx)
            }
        },

        // 자동완성 켜기 및 선택
        selectValue(keycode, str, idx, centerIdx, centerName) {
            if (this.isAutoCounselor) {
                const hasClass = document.querySelector('.r').classList.contains('key');
                if (keycode === 'down') {
                    if (!hasClass) {
                        console.log('여기탐')
                        const thisEl = document.querySelectorAll('.r li')[0];
                        document.querySelector('.r').classList.add('key');
                        thisEl.classList.add('sel');
                        thisEl.focus();
                    } else {
                        const lastEl = document.querySelector('.r li:last-child');
                        const thisEl = document.querySelector('.r li.sel');
                        const nextEl = thisEl.nextElementSibling;
                        if (!lastEl.classList.contains('sel')) {
                            thisEl.classList.remove('sel');
                            nextEl.classList.add('sel');
                            nextEl.focus();
                        }
                    }
                }

                if (keycode === 'up' && hasClass) {
                    const firstEl = document.querySelectorAll('.r li')[0];
                    const thisEl = document.querySelector('.r li.sel');
                    const prevEl = thisEl.previousElementSibling;
                    if (!firstEl.classList.contains('sel')) {
                        thisEl.classList.remove('sel');
                        prevEl.classList.add('sel');
                        prevEl.focus();
                    } else {
                        document.querySelector('.s').focus();
                    }
                }

                if (keycode === 'enter' && hasClass) {
                    this.changeValue(str, 'counselor', idx, centerIdx, centerName);
                }
            }

            if (this.isAutoClient) {
                const hasClass = document.querySelector('.c').classList.contains('key');
                if (keycode === 'down') {
                    if (!hasClass) {
                        const thisEl = document.querySelectorAll('.c li')[0];
                        document.querySelector('.c').classList.add('key');
                        thisEl.classList.add('sel');
                        thisEl.focus();
                    } else {
                        const lastEl = document.querySelector('.c li:last-child');
                        const thisEl = document.querySelector('.c li.sel');
                        const nextEl = thisEl.nextElementSibling;
                        if (!lastEl.classList.contains('sel')) {
                            thisEl.classList.remove('sel');
                            nextEl.classList.add('sel');
                            nextEl.focus();
                        }
                    }
                }

                if (keycode === 'up' && hasClass) {
                    const firstEl = document.querySelectorAll('.c li')[0];
                    const thisEl = document.querySelector('.c li.sel');
                    const prevEl = thisEl.previousElementSibling;
                    if (!firstEl.classList.contains('sel')) {
                        thisEl.classList.remove('sel');
                        prevEl.classList.add('sel');
                        prevEl.focus();
                    } else {
                        document.querySelector('.s').focus();
                    }
                }

                if (keycode === 'enter' && hasClass) {
                    this.changeValue(str, 'client', idx);
                }
            }
        },

        // 상담시간 array 구조 생성
        setConsTime() {
            this.consTimeList = []
            let time = 9
            for (var i = 0; i < 26; i++) {
                if (time < 10) {
                    if (i % 2 == 0) {
                        this.consTimeList.push({
                            time: '0' + (time) + ':00',
                            use: false,
                            can: true
                        })
                    } else {
                        this.consTimeList.push({
                            time: '0' + (time) + ':30',
                            use: false,
                            can: true
                        })
                        time += 1
                    }
                } else {
                    if (i % 2 == 0) {
                        this.consTimeList.push({
                            time: (time) + ':00',
                            use: false,
                            can: true
                        })
                    } else {
                        this.consTimeList.push({
                            time: (time) + ':30',
                            use: false,
                            can: true
                        })
                        time += 1
                    }
                }
            }
        },

        // 상담사 전체목록 호출
        getCounselorList() {
            let params = {
                idxCrmCenter: 0 // 본원 센터 idx
            }
            this.axios.get('/api/v2/user/list', {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.counselorList = res.data.userList
                    } else {
                        //alert(res.data.result)
                        this.counselorList = []
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response.data.path === '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            return [year, month, day].join('-');
        },

        postPersonInfo() {

            if (!EmptyCheck(this.name, '고객명을')) {
                return false
            }

            if (!EmptyCheck(this.reservationStatus, '예약현황을')) {
                return false
            }

            // 근무요일 테이블 파라미터형태로 변경
            let consTimeList = []
            let copyConsTimeList = JSON.parse(JSON.stringify(this.consTimeList))
            for (var item of copyConsTimeList) {
                if (item.use) {
                    delete item['use']
                    consTimeList.push(item)
                }
            }

            console.log('consTimeList', consTimeList)
            this.consTime = ''
            consTimeList.reverse();
            for (var time of consTimeList) {
                this.consTime = time.time + "/" + this.consTime
            }

            if (!EmptyCheck(this.consTime, '상담시간을')) {
                return false
            }

            // 핸드폰 번호를 전부 입력한 경우에만 핸드폰 포맷으로 파싱
            if (this.phone2 !== '' && this.phone3 !== '') {
                var phone = this.phone1 + '-' + this.phone2 + '-' + this.phone3
            } else {
                var phone = ''
            }

            if (phone === '') {
                alert('핸드폰번호를 입력해주세요')
                return false;
            }

            /* if (!EmptyCheck(this.clientStatus, '진행상황을')) {
                return false
            } */

            var vo = {
                name: this.name,
                gender: this.gender,
                birth: this.formatDate(this.birth),
                email: this.email,
                idxCrmMetaRoute: this.idxCrmMetaRoute,
                phone: phone
            }

            this.axios.post('/api/v1/client/person', vo)
                .then(res => {
                    if (res.data.err === 0) {
                        //this.$router.push('/origin/person_manage')
                        this.idxCrmPerson = res.data.idxCrmPerson
                        this.postClientInfo()
                    } else {
                        alert(res.data.result)
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 유입경로, 상담구분, 상담유형 리스트
        getMetaList() {
            this.axios.get('/api/v1/client/meta', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.typeList = res.data.typeList
                        this.routeList = res.data.routeList
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

    }
}
</script>

<style lang="scss" scoped>
.modal {
    &.modal-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        z-index: 30;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
    }

    &-window {
        background: #fff;
        border-radius: 4px;
        overflow: hidden;
    }

    &-content {
        padding: 10px 20px;
        background: #fff;
    }

    &-footer {
        // background: #ccc;
        padding: 10px;
        text-align: right;
    }
}

.modal-enter-active,
.modal-leave-active {
    transition: opacity 0.4s;

    .modal-window {
        transition: opacity 0.4s, transform 0.4s;
    }
}

.modal-leave-active {
    transition: opacity 0.4s ease 0.2s;
}

.modal-enter,
.modal-leave-to {
    opacity: 0;

    .modal-window {
        opacity: 0;
        transform: translateY(-20px);
    }
}

#app {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    .search {
        position: relative;
        margin: 0 auto;
        width: 100%;
        max-width: 600px;

        .s {
            float: left;
            margin-left: 15px;
            width: 255px;
            height: 30px;
            object-fit: contain;
            border-radius: 3px;
            border: solid 1px #cccccc;
            background-color: #ffffff;
            padding-left: 15px;

            &::placeholder {
                font-size: 14px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 4.29;
                letter-spacing: normal;
                text-align: left;
                color: #cccccc;
            }
        }

        .c {
            display: none;
            position: absolute;
            left: 0;
            top: 28px;
            width: 100%;
            height: 156px;
            overflow-y: auto;
            list-style: none;
            padding-left: 20px;

            &.show {
                display: block;
                z-index: 10;
            }

            li {
                margin-top: -1px;
                padding: 0 20px;
                width: 91%;
                height: 40px;
                background-color: #fff;
                box-sizing: border-box;
                border: 1px solid #dcdcdc;
                outline: none;
                font-size: 16px;
                line-height: 40px;
                cursor: pointer;

                &:hover,
                &.sel {
                    background-color: darken(#fff, 5%);
                }

                &:last-child {
                    border-radius: 0px 0px 4px 4px;
                }
            }
        }

        .r {
            display: none;
            position: absolute;
            left: 0;
            top: 28px;
            width: 100%;
            height: 156px;
            overflow-y: auto;
            list-style: none;
            padding-left: 20px;

            &.show {
                display: block;
                z-index: 10;
            }

            li {
                margin-top: -1px;
                padding: 0 20px;
                width: 91%;
                height: 40px;
                background-color: #fff;
                box-sizing: border-box;
                border: 1px solid #dcdcdc;
                outline: none;
                font-size: 16px;
                line-height: 40px;
                cursor: pointer;

                &:hover,
                &.sel {
                    background-color: darken(#fff, 5%);
                }

                &:last-child {
                    border-radius: 0px 0px 4px 4px;
                }
            }
        }

        p {
            padding: 10px 0;
            text-align: right;
            font-size: 12px;
        }
    }
}

.point {
    font-family: gulim;
    font-size: 9pt;
    color: #FAF7E7;
}

.textbox {
    font-family: gulim;
    font-size: 9pt;
    color: #736257;
    height: 20px;
}

.msg_wrap {
    width: 230px;
}

.msg_deco_wrap {
    border-radius: 13px 13px 0 0;
    background: #efefef;
    padding: 12px 0 0 0;
}

.msg_deco {
    width: 40px;
    height: 2px;
    background: #ababab;
    border-radius: 20px;
    margin: 0 auto;
}

.msg_box {
    background: #efefef;
    padding: 8px 20px 10px 20px;
    display: block;
    height: 200px;
}

.msg_box textarea {
    width: 100%;
    height: 100%
}

.msg_send {
    background: #efefef;
    padding: 0 20px 20px 20px;
}

.msg_footer {
    background: #efefef;
    border-radius: 0 0 13px 13px;
    padding: 0 20px 20px 20px;
    text-align: center;
}
</style>
